import { EditMode } from 'types/quickLinksEdit';
import { ActionType } from '../action-types'

export const initialState: EditMode = {
    isEditMode : false,
    isAddContextBox: false,
    isEditContextLabel: false,
    userContextTabs: [],
    userContext:[],
    isEditDaashboardLoader: false,
    category_id: '',
    isQuickLinksAddQs: false,
    quickLinks:[],
    activeTabId:'',
    updatedQuickLinkIndexList: [],
    updatedContextLabel : '',
    isNewCardOpen: false,
    isEditUserContextTab: false,
    userContextIsLoading: false,
    activeLeftQuickLinkQuestionIndex: -1,
    activeLeftQuickLinkIndex: -1,
    activeLeftQuickLinksSuggestionIndex: -1,
    buttonLoader:false,
    isButtonAddLoader: false,
    addQsLoader:false,
    updateRemovedDashboard: false,
    isEditStatus:false,
    isEditQuickLinkQsStatus:false,
    update_single_asked_question_index: '',
    is_show_quick_link_loader:false,
    update_group_asked_question_index:'',
    is_show_group_quick_link_loader:false,
    hide_show_textarea: false,
    isDragEnabled:false,
    selectedGroupName: '',
    isLoading: false,
  }
const reducer = (state: EditMode = initialState, action: any): EditMode => {
    switch (action.type) {
          case ActionType.SHOW_LOADING_SPINNER_BEFORE_GETTING_GORUP_LIST:
            return { ...state, isLoading: action.payload }

          case ActionType.UPDATE_SELECTED_GROUP_NAME:
            return { ...state, selectedGroupName: action.payload }

          case ActionType.HIDE_AND_SHOW_TEXTAREA:
            return { ...state, hide_show_textarea: action.payload }

          case ActionType.UPDATE_LEFT_ACTIVE_QUICK_QUESTION_INDEX:
          return { ...state , activeLeftQuickLinkQuestionIndex: action.payload };

          case ActionType.UPDATE_LEFT_ACTIVE_QUICK_LINK_INDEX:
            return { ...state , activeLeftQuickLinkIndex: action.payload };
          case ActionType.UPDATE_LEFT_ACTIVE_QUICK_SUGGESTION_INDEX:
            return { ...state, activeLeftQuickLinksSuggestionIndex: action.payload}

          case ActionType.INITIAL_USER_CONTEXT_LOADER:
            return { ...state, userContextIsLoading: action.payload }

          case ActionType.SET_EDIT_CATEGORY_CARD_IS_OPEN:
            return {...state, isNewCardOpen: action.payload }
          case ActionType.IS_TOGGLE_EDIT_MODE:
          return {...state, isEditMode: action.payload}

          case ActionType.IS_OPEN_ADD_NEW_CONTEXT_BOX:
          return {...state, isAddContextBox: action.payload}

          case ActionType.IS_OPEN_EDIT_CONTEXT_LABEL:
            return {...state, isEditContextLabel: action.payload}

          case ActionType.UPDATE_EDIT_MODE_USER_CONTEXT:
            return {...state, userContextTabs: [...state.userContextTabs, action.payload]}

          case ActionType.USER_CONTEXT_TABS:
            return {...state, userContextTabs: action.payload}

          case ActionType.IS_EDIT_DASHBOARD_CONTEXT_LOADER:
            return {...state, isEditDaashboardLoader: action.payload}

            case ActionType.IS_REMOVE_QUESTION_LOADER:
              return {...state, addQsLoader: action.payload}

          case ActionType.QUICK_LINKS_NEW_CATEGORY_ID:
          return {...state, category_id: action.payload}

          case ActionType.IS_QUICK_LINKS_ADD_QUESTION:
          return {...state, isQuickLinksAddQs: action.payload}

          case ActionType.QUICK_LINKS_DASHBOARD:
          return {...state, quickLinks: action.payload}

          case ActionType.SELECTED_TAB_ID:
          return {...state, activeTabId: action.payload}

          case ActionType.MODIFIED_QUICK_LINKS_WITH_INDEX_CHANGED:
          return {...state, updatedQuickLinkIndexList: action.payload}

          case ActionType.GET_UPDATED_CONTEXT_LABEL:
            return {...state, updatedContextLabel: action.payload }

          case ActionType.UPDATE_QUICK_LINK_QS_STATUS:
            return {
              ...state,
              quickLinks: [
                ...state.quickLinks.map((item) => {
                  if (item.id === action.payload.category_id) {
                    const updatedItems = item.suggestions.map((suggestion: any) => {
                      if (suggestion.id === action.payload.question_id) {
                        if (action.payload.status === 'red') {
                          return {
                            ...suggestion,
                            is_red_question: true,
                            is_inactive: false,
                            is_yellow_question: false,
                            is_cli_ran: false,
                            is_changed: false
                          };
                        } else if (action.payload.status === 'light_grey') {
                          return {
                            ...suggestion,
                            is_inactive: true,
                            is_red_question: false,
                            is_yellow_question: false,
                            is_cli_ran: false,
                            is_changed: false
                          };
                        } else if (action.payload.status === 'yellow') {
                          return {
                            ...suggestion,
                            is_yellow_question: true,
                            is_red_question: false,
                            is_inactive: false,
                            is_cli_ran: false,
                            is_changed: false
                          };
                        }else if (action.payload.status === 'grey') {
                          return {
                            ...suggestion,
                            is_yellow_question: false,
                            is_red_question: false,
                            is_inactive: false,
                            is_cli_ran: false,
                            is_changed: false
                          };
                        } else {
                          return {
                            ...suggestion,
                            is_cli_ran: true,
                            is_changed: false,
                            is_red_question: false,
                            is_yellow_question: false,
                            is_inactive: false
                          };
                        }
                      }
                      return suggestion;
                    });
                    return {
                      ...item,
                      suggestions: updatedItems,
                    };
                  }
                  return item;
                }),
              ],
            };


          case ActionType.IS_EDIT_QUESTION_STATUS:
            return {...state, isEditStatus: action.payload }

          case ActionType.UPDATE_QUICK_LINK_GROUP_QS_STATUS:
          return {...state, quickLinks: [...state.quickLinks.map((item) => {
            if (item.id === action.payload.category_id) {
              const updatedSuggestions = item.suggestions.map((suggestion:any) => {
                const updatedQuestions = suggestion.questions.map((question:any) => {
                  if (question.id === action.payload.question_id) {
                    // Find the matching question with priority: red, light_grey, yellow, grey
                    if (action.payload.status === 'red') {
                      return {
                        ...question,
                        is_red_question: true,
                        is_inactive: false,
                        is_yellow_question: false,
                        is_cli_ran: false,
                        is_changed: false
                      };
                    } else if (action.payload.status === 'light_grey') {
                      return {
                        ...question,
                        is_inactive: true,
                        is_red_question: false,
                        is_yellow_question: false,
                        is_cli_ran: false,
                        is_changed: false
                      };
                    } else if (action.payload.status === 'yellow') {
                      return {
                        ...question,
                        is_yellow_question: true,
                        is_red_question: false,
                        is_inactive: false,
                        is_cli_ran: false,
                        is_changed: false
                      };
                    } else if (action.payload.status === 'grey') {
                      return {
                        ...question,
                        is_cli_ran: false,
                        is_red_question: false,
                        is_yellow_question: false,
                        is_inactive: false,
                        is_changed: false
                      };
                    } else {
                      return {
                        ...question,
                        is_cli_ran: true,
                        is_changed: false,
                        is_red_question: false,
                        is_yellow_question: false,
                        is_inactive: false
                      };
                    }
                  }
                  return question;
                });

                return {
                  ...suggestion,
                  questions: updatedQuestions,
                };
              });
              return {
                ...item,
                suggestions: updatedSuggestions,
              };
            }

            return item;
          })]}

          case ActionType.UPDATE_SUGGESTIONS_WITH_CATEGORY_ID:
            return { ...state , quickLinks: [...state.quickLinks.map((item: any)=> {
              if(item.id == action.payload.category_id) {
                item.suggestions = action.payload.suggestions
              }
              return item
            })] }

            case ActionType.UPDATE_QUESTION_WITH_CATEGORY_ID_QUESTION_ID:
            return { ...state , quickLinks: [...state.quickLinks.map((item: any)=> {
              if(item.id == action.payload.category_id) {
                   item.suggestions.map((question: any) => {
                      if(question.id == action.payload.question_id) {
                         question.question = action.payload.question
                         question.agent = action.payload.agent
                         question.focus = action.payload.focus
                         question.sources = action.payload.sources
                      }
                      return question;
                   })
              }
              return item
            })] }

            case ActionType.UPDATE_QUESTION_WITH_CATEGORY_ID_QUESTION_ID_GROUP_NAME:
            return { ...state , quickLinks: [...state.quickLinks.map((item: any)=> {
              if(item.id == action.payload.category_id) {
                   item.suggestions.map((group: any) => {
                       if(group.group_name == action.payload.group_name) {
                          group.questions.map((question: any) => {
                            if(question.id == action.payload.question_id) {
                               question.question = action.payload.question
                               question.agent = action.payload.agent
                               question.focus = action.payload.focus
                               question.sources = action.payload.sources
                            }
                            return question;
                         })
                       }
                   })
              }
              return item
            })] }

            case ActionType.IS_EDIT_USER_CONTEXT_TABS:
            return {...state, isEditUserContextTab: action.payload }

            case ActionType.IS_EDIT_QUICKLINK_QUESTION_STATUS:
              return {...state, isEditQuickLinkQsStatus: action.payload }

            case ActionType.IS_EDIT_MODE_BUTTON_LOADER:
              return {...state, buttonLoader: action.payload}

              case ActionType.IS_ADD_QUESTION_BUTTON_LOADER:
                return {...state, isButtonAddLoader: action.payload}

          case ActionType.DELETE_SELECTED_CATEGORY_CARD:
          return {...state, quickLinks:[...state.quickLinks.filter((item: any)=>(
            item.id !== action.payload
          ))]}

          case ActionType.REMOVE_SELECTED_CONTEXT_TAB:
          return {...state, userContextTabs: [...state.userContextTabs.filter((item)=>(
            item.id !== action.payload
          ))]}
        case ActionType.REMOVE_AND_UPDATE_QUICK_LINK_DASHBOARD:
          return { ...state, updateRemovedDashboard: action.payload }

        case ActionType.UPDATE_SINGLE_ASKED_QUESTION_INDEX:
          return {...state, update_single_asked_question_index: action.payload}

        case ActionType.UPDATE_GROUP_QUICK_LINK_LOADER:
          return {...state, is_show_group_quick_link_loader:action.payload}

        case ActionType.UPDATE_GROUP_ASKED_QUESTION_INDEX:
          return {...state, update_group_asked_question_index:action.payload}

        case ActionType.UPDATE_QUICK_LINK_LOADER:
          return {...state, is_show_quick_link_loader:action.payload}

          case ActionType.IS_DRAG_DROP_ENABLED:
          return {...state, isDragEnabled:action.payload}

          default:
            return state;
        }
    }

    export default reducer;