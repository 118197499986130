import { Dispatch } from "redux";
import { RootState } from "state";
import { newChatAction, sendMessageAction, scrollMessagesListToBottomAction } from "./ChatActions";
import { Message } from "types/message";
import { debounce } from "lodash";
import { Project } from "types/project";
import { EmptySelectedFile } from "./AgentActions";
export const submitQueryActionIMPL = (q: string) => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      const advanced_metadata = getState().agent.advanced_metadata;
      const selectedFocus = getState().agent.selectedCommand?.is_visible ? getState().agent.selectedCommand?.display || "": "";
      const currentChatId = getState().chat.current_thread_details?.id;
      const selected_agents = getState().agent.selected_agent;
      const selected_sources = getState().agent.selected_sources;
      const selected_files = getState().agent.selectedFiles.filter((file:any) => file?.chat_id === getState().chat.current_thread_details?.id);
      const selectedCommand:any = getState().agent.selectedCommand;
      const file_upload_cont = getState().chat.current_thread_details?.file_upload_count || 0;
      const selected_project_id = new URLSearchParams(window.location.search).get("project_id");
      const project_details = getState().chat.projects?.find((project: Project) => project.id === selected_project_id);

      const topic = q;
      const current_chat_is_processed = getState().chat.current_thread_details?.is_chat_processed;

      const messages_processing_status = getState().chat.messages?.map((message: Message) => {
        return (message?.response?.response_component?.processing_status?.state==="done"
          ||message?.response?.response_component?.processing_status?.state==="blocked"
      ||message?.response?.response_component?.processing_status?.state==="failure")?"done":"not_done";
    });
      const isChatEnable = (current_chat_is_processed !== false) && (!messages_processing_status.includes("not_done"));

      const query_sources = (
        (project_details?.project_metadata?.attachments?.length === 0 || project_details?.project_metadata?.attachments?.length === undefined) &&
        (!selectedCommand?.file_upload_enabled || (selectedCommand?.file_upload_enabled && file_upload_cont === 0 && (!selected_files || selected_files?.length === 0))) &&
        selected_sources?.length > 0
      ) ? selected_sources : [];

      const project_id_metadata = selected_project_id ? selected_project_id : undefined;
      const selected_advanced_metadata = Object.keys(advanced_metadata).length > 0 ? advanced_metadata : undefined;

      const query_focus = !selected_project_id||(selected_project_id && !!getState().agent.selectedCommand?.properties?.project_support)? selectedFocus:""
      //! #fix_this_later selected_agent?.agent_name is not considerting that agent_name can be empty
      if (topic) {
        if (!currentChatId ||(currentChatId && !isChatEnable)) {
          dispatch(
            await newChatAction({
              topic,
              agent: selected_agents?.agent_name||"",
              focus: ( getState().agent?.selectedCommand?.is_visible
                  ? query_focus
                  : ""),
              sources: query_sources,
              selected_files:selected_files,
              metadata: {
                project_id: project_id_metadata,
              },
              user_metadata: {
                project_id: project_id_metadata,
                advanced_metadata: selected_advanced_metadata,
                alias_data: undefined,
              }
            })
          );
          dispatch(EmptySelectedFile());
        } else {
          dispatch(await sendMessageAction({
            chat_id: currentChatId,
            is_new_chat: false,
            sources: query_sources,
            focus: query_focus,
            message_intent: "question",
            user_message: {
              author: {
                role: "user",
                tool: "",
                metadata: "",
              },
              content:{
                type: `${ selectedCommand?.file_upload_enabled &&  selected_files && selected_files.length > 0 ? "multimedia" : "text"}`,
                message:topic||"",
                metadata: JSON.stringify({
                  ...(selectedCommand?.file_upload_enabled && selected_files && selected_files.length > 0 ? {"attachments": selected_files} : {})
                })
              },
              metadata: JSON.stringify({
                project_id: project_id_metadata,
                advanced_metadata: selected_advanced_metadata,
                alias_data: undefined,
              })
            },
            message_id: "",
            question_id: "",
          })
          );
          dispatch(EmptySelectedFile());

        }
        scrollMessagesListToBottomAction(true);
      }
    } catch (error) {
      console.log("Unhandled promise rejection:", error);
    }
  };
};

export const submitQueryAction = (() => {
  const debouncedAction = debounce((q: string, dispatch: Dispatch<any>) => {
    dispatch(submitQueryActionIMPL(q));
  }, 750);

  const actionCreator = (q: string) => {
    return (dispatch: Dispatch<any>) => {
      debouncedAction(q, dispatch);
    };
  };

  actionCreator.cancel = debouncedAction.cancel;
  actionCreator.flush = debouncedAction.flush;

  return actionCreator;
})();