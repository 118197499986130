import { getCurrentUser , signOut , signIn , signUp , confirmSignUp , resendSignUpCode, resetPassword , confirmResetPassword} from 'aws-amplify/auth';

import { isDemoUser } from "utils/isDemoUser";
import { isInternalUser } from "utils/isInternalUser";
import * as userServices from "../../services/user"
import { ActionType } from '../action-types';
import { Dispatch  } from "redux";
import { push , alert , toggleComponentLoading } from './customRouter';
import { CUSTOMER_ID } from "config"
import { RootState, store as reduxStore} from "state";
import { clearAnswer, setAnswerSubscriptionAction } from "./AnswerActions";
import { updateQuickLinksCategories } from "./QuickLinksActions";
import { editToggleMode } from "./EditModeActions";
import { getChatCommands } from "./AgentActions";

export const resetRedirect = () => {
	return {
		type: ActionType.RESET_REDIRECT ,
		payload: ""
	}
}

const startPasswordResetLoading = (dispatch: Dispatch , loading: boolean) => {
	dispatch({
		type: ActionType.STATE_PASSWORD_RESET_LOADING,
		payload: loading
	})
}

// const toggleComponentLoading = (actionType: string, loading: boolean) => ({
// 	type: actionType,
// 	payload: loading
// });
//setupAuth function is reuable function function is responsible to setup the auth user
const  setupAuth = async ({dispatch ,email , redirect = true
	//  cognitoUser
	}: any) => {

		try {
			// For development and stage environment only
			// if(! await CallGetServerStatus({dispatch})) return;
			const environment = (reduxStore.getState() as any).siteSettings.environment;

            if(environment == "dev" || environment == "stage") {
                if(! await CallGetServerStatus({dispatch})) return;
            }

		} catch (error) {
			dispatch(alert())
			return;
		}

		try {
			const userResponse = await userServices.getUser();
		if (userResponse.status !== 200) {
			throw userResponse.body.message;
		}

		email = userResponse.body?.settings?.user_email||email;

		const isInternal = isInternalUser(email);
		const isDemo = isDemoUser(email);

		const domain = email.substring(email.lastIndexOf("@") +1);
		const name   = email.substring(0, email.lastIndexOf("@"));

		let userRole = "";
		if(name == "super" && domain == "huma.ai") {
			userRole = "Super Admin";
		} else if(domain == "huma.ai") {
			userRole = "admin";
		} 
 
		const userObj = {
			authenticated: true,
			email: email,
			user_id:userResponse.body.settings.user_id,
			internal: isInternal,
			isDemoUser: isDemo,
			settings: userResponse.body.settings,
			role: userRole
		}
	
		dispatch({
			type 	: ActionType.SIGN_IN_USER_SUCCESS,
			payload : userObj
		});
		dispatch({
			type: ActionType.UPDATE_SHOW_CUSTOMER_NOTICE,
			payload: userResponse.body.settings.show_customer_notice
		})
		dispatch(getChatCommands());
		const query = window.location.search
		if(query){
			const params = new URLSearchParams(query);
			const redirectURL = params.get("redirect");
			return (redirectURL&&dispatch(push(redirectURL)))
		}
		if(redirect){
			return dispatch(push('/'))
		}
		} catch (error: any) {
			dispatch(alert())
		}
		
}

const CallGetServerStatus = async ({ dispatch }: any) => {
	const isServersAvailable = false;
	// const siteSettingres = await getSiteSettings();

	// if(siteSettingres.status == 200) {
		// const environment  = siteSettingres.body.environment;
		// if (environment == "dev" || environment == "stage") {
			try {
				const user = await getCurrentUser();
				if(user){

					const response = await userServices.getServersStatus();
					const results = response.body;
					dispatch({
						type: ActionType.FETCH_SYSTEM_STATUS_COMPLETED,
						payload: response.body
					})
	
					if (results && !results.are_all_services_available) {
						userServices.startServers();
						dispatch(push('/system-boot-up'));
						return isServersAvailable;
					} else {
						return !isServersAvailable;
					}
				}
				
			} catch (error) {
				dispatch(alert())
			}
				
			
			
		// } else {// 	// For production environment - assume all servers are available
		// 	return true;
		// }
	// } else {
	// 	dispatch(alert());
	// 	return isServersAvailable;
	// }

}

const checkIsEmailValid = (email:string, actionType:string, error:string, isValid:boolean) => {
	return {
		type: actionType,
		payload: {
			value: email ,
			valid: isValid,
			error: error
		}
	}
}

const checkIsPasswordValid = (password:string, actionType:string, error:string, isValid:boolean) => {
	return {
		type: actionType,
		payload: {
			value: password ,
			valid: isValid,
			error: error
		}
	}
}

const checkIsCodeValid = (code:string, actionType:string, error:string, isValid:boolean) => {
	return {
		type: actionType,
		payload: {
			value: code ,
			valid: isValid,
			error: error
		}
	}
}

export const signInUser = ({email, password}: {email: string, password: string}) => {
	return async (dispatch: Dispatch) => {
		dispatch(toggleComponentLoading(ActionType.SIGN_IN_LOADING_CHANGE , true));

		dispatch(checkIsEmailValid(email, ActionType.SIGN_IN_EMAIL_CHANGED, '', false));
		dispatch(checkIsPasswordValid(password, ActionType.SIGN_IN_PASSWORD_CHANGED, '', false));

		try {
			email = email.toLowerCase();
			const user = await signIn({ username: email, password });

			const expirationTime = new Date().getTime() + 25 * 24 * 60 * 60 * 1000;
			localStorage.setItem('refreshTokenExpires', expirationTime.toString())

			if(user.nextStep.signInStep == "RESET_PASSWORD") {
				dispatch(toggleComponentLoading(ActionType.SIGN_IN_LOADING_CHANGE , false));
				dispatch(push('/account/reset-password'));
				return;
			}

			// "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
			if(user.nextStep.signInStep == "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
				dispatch(toggleComponentLoading(ActionType.SIGN_IN_LOADING_CHANGE , false));
				dispatch(push('/account/reset-password'));
				return;
			}
			// "CONFIRM_SIGN_UP when otp is not confirmed"
			if(user.nextStep.signInStep == "CONFIRM_SIGN_UP") {
				dispatch(toggleComponentLoading(ActionType.SIGN_IN_LOADING_CHANGE , false));
				dispatch({
					type: ActionType.ACCOUNT_TO_CONFIRM ,
					payload: {
						email: email ,
						password: password
					}
				});
				dispatch(push('/account/confirm'));
				return;
			}
			setupAuth({dispatch , email , user}).then(() => {
				dispatch(toggleComponentLoading(ActionType.SIGN_IN_LOADING_CHANGE , false));
			});

		  } catch (err: any) {
			dispatch(toggleComponentLoading(ActionType.SIGN_IN_LOADING_CHANGE , false));
			dispatch(checkIsEmailValid(email, ActionType.SIGN_IN_EMAIL_CHANGED, '', true));

			try {

				switch (err.name) {
					case "UserAlreadyAuthenticatedException":
						// reload the page
						window.location.reload();
						return;
					case "UserNotConfirmedException":
						dispatch({
							type: ActionType.ACCOUNT_TO_CONFIRM ,
							payload: {
								email: email ,
								password: password
							}
						});
						dispatch(push('/account/confirm'));
					  return;
					case "PasswordResetRequiredException":
					  dispatch(push('/account/reset-password'));
					  return;
					case "NotAuthorizedException":
						dispatch(checkIsPasswordValid(password, ActionType.SIGN_IN_PASSWORD_CHANGED, 'Incorrect username or password', false));
					  return;
					case "UserNotFoundException":
						dispatch(checkIsEmailValid(email, ActionType.SIGN_IN_EMAIL_CHANGED, 'Invalid email address.', false));
						dispatch(checkIsPasswordValid(password, ActionType.SIGN_IN_PASSWORD_CHANGED, '', true));
					  return;
					default:
						dispatch(alert());
					}
			} catch (error) {
				console.log("error",error)
				dispatch(alert());
			}
		}
	}
}

// action is responsible to check if user is logged in or not.
export const checkUserAuth = (redirect = false) => {
	return async (dispatch: Dispatch) => {

		try {
			dispatch({
				type: ActionType.APP_LOADED ,
				payload: false
			})
			const user = await getCurrentUser();
			const email = user.username;
			setupAuth({dispatch , email , user , redirect } ).then(() => {
				dispatch({
					type: ActionType.APP_LOADED ,
					payload: true
				});
			})
		
		//data loaded will be true here test
		} catch(e) {
			const path = window.location.pathname
			if(path!=="/"){
				dispatch(push("/?redirect="+location.pathname+location.search))
			}
			dispatch({
				type: ActionType.APP_LOADED ,
				payload: true
			})
		}
	}
}


export const logOutAndClearData = () => {
	return async (dispatch: Dispatch<any>, getState: () => RootState) => {
		const {
			chat: { subscription },
			answer: {subscription:answerSubscriptions}
		  } = getState();
		const { eventReceiver } = subscription;
		dispatch(clearAnswer());
		dispatch(updateQuickLinksCategories([]));
		if(eventReceiver) {
			eventReceiver.unsubscribe();
		}
		if(answerSubscriptions.subscribeUtteranceState){
			answerSubscriptions.subscribeUtteranceState.unsubscribe();
		}
		dispatch(setAnswerSubscriptionAction({ name: "subscribeUtteranceState", value: null }));
		await signOut();

		const userObj = {
			authenticated: false,
			email: "",
			user_id:"",
			internal: "",
			isDemoUser: "",
			settings: "",
			role: "customer"
		}
		dispatch({
			type: ActionType.SIGN_OUT_USER_SUCCESS ,
			payload: userObj
		});
		dispatch({
			type: ActionType.REDUX_STATE_RESET ,
		});
		dispatch({
			type: ActionType.APP_LOADED ,
			payload: true
		});
		localStorage.clear();
		dispatch(push("/"));
		dispatch(editToggleMode(false));
	}
  }

export const registerUser = ({email, password}: {email: string, password: string}) => {
	return async (dispatch: Dispatch) => {

		dispatch(checkIsEmailValid(email, ActionType.REGISTER_EMAIL_CHANGED, '', false));
		dispatch(checkIsPasswordValid(password, ActionType.REGISTER_PASSWORD_CHANGED, '', false));

		dispatch(toggleComponentLoading(ActionType.REGISTER_LOADING_CHANGE , true));
		try {
			email = email.toLowerCase();

			await signUp({
				username: email,
				password,
				options: {
					userAttributes: {
						email
					}
				}
			});

			dispatch(toggleComponentLoading(ActionType.REGISTER_LOADING_CHANGE , false))


			dispatch({
				type: ActionType.REGISTER_USER_SUCCESS
			})

			dispatch({
				type: ActionType.ACCOUNT_TO_CONFIRM ,
				payload: {
					email: email ,
					password: password
				}
			});

			dispatch( push('/account/confirm') );

		  } catch (err: any) {
			dispatch(toggleComponentLoading(ActionType.REGISTER_LOADING_CHANGE , false))
			switch (err.name) {
				case "UsernameExistsException":
					dispatch(checkIsEmailValid(email, ActionType.REGISTER_EMAIL_CHANGED, 'User already exists', false));
					dispatch(alert(err.message));
				  return;
				case "InvalidPasswordException":
					dispatch(checkIsPasswordValid(password, ActionType.REGISTER_PASSWORD_CHANGED, 'Password did not conform with policy', false));
					dispatch(alert(err.message));
				  return;
				default:
					dispatch(alert());
			  }
		  }
	}
}

export const verifyUser = ({email, password , code}: any) => {

	return async (dispatch: Dispatch<any>) => {
		
		dispatch(checkIsCodeValid(code, ActionType.VERIFY_CODE_CHANGED, '', false));
		dispatch(toggleComponentLoading(ActionType.CONFIRM_ACCOUNT_FORM_LOADING_CHANGE , true))

		try {
			email = email.toLowerCase();
			await confirmSignUp({ username: email, confirmationCode: code });
			dispatch(toggleComponentLoading(ActionType.CONFIRM_ACCOUNT_FORM_LOADING_CHANGE , false));

			dispatch(checkIsEmailValid(email, ActionType.SIGN_IN_EMAIL_CHANGED, '', true));
			dispatch(checkIsPasswordValid(password, ActionType.SIGN_IN_PASSWORD_CHANGED, '', true));

				if (CUSTOMER_ID == '009' || CUSTOMER_ID == '018' || CUSTOMER_ID == '022' || CUSTOMER_ID == '021') {
					dispatch(push('/account/set-context'));
				} else {
					dispatch( signInUser({email , password}) );
				}

		  } catch(error: any) {
			  console.log("error" , error)
			dispatch(toggleComponentLoading(ActionType.CONFIRM_ACCOUNT_FORM_LOADING_CHANGE , false));

			switch (error.name) {
				case "CodeMismatchException":
					dispatch(checkIsCodeValid(code, ActionType.VERIFY_CODE_CHANGED, 'The provided code is incorrect', false));
				  return;
				default:
					dispatch(alert());
			  }
			return;

		  }
	}
}

export const resendCodeAction =  (email: string) => {
	return async (dispatch: Dispatch) => {
		try {
			await resendSignUpCode({ username: email });
		  } catch(err:any) {
			  dispatch(alert(err.message as string));
		  }
	}
}

export const resentForgotPasswordAction = (email: string) => {
	return async (dispatch: Dispatch) => {
		
		try {
			await resetPassword({ username: email });
		  } catch(err:any) {
			  dispatch(alert(err.message as string));
			
		  }
	}

}

export const updatePassowrdResetState = (state: string) => {
	return {
		type: ActionType.UPDATE_PASSWORD_RESET_STATE ,
		payload: state 
	}
}

export const initPasswordReset = (email: string) => {
	return async (dispatch: Dispatch) => {
		startPasswordResetLoading(dispatch , true);
		dispatch(checkIsEmailValid(email, ActionType.FORGOT_EMAIL_CHANGED, "", false));
		try {
			await resetPassword({ username: email });

			startPasswordResetLoading(dispatch , false);
			dispatch(updatePassowrdResetState('EMAIL_SENT'));

		} catch (error: any) {
			startPasswordResetLoading(dispatch , false);
			switch (error.name) {
				
			    case "UserNotFoundException":
					startPasswordResetLoading(dispatch , false);
					dispatch(updatePassowrdResetState('EMAIL_SENT'));
				// 	dispatch({
				// 		type: ActionType.FORGOT_EMAIL_CHANGED,
				// 		payload: {
				// 			value: email ,
				// 			valid: false,
				// 			error: "Invalid email address."
				// 		}
				// 	});
			    
			      return;
			    case "InvalidParameterException":
					dispatch(checkIsEmailValid(email, ActionType.FORGOT_EMAIL_CHANGED, "User has not been confirmed.", false));
			      return;
				case "LimitExceededException":
					dispatch(alert(error.message))
					return;
			    default:
					dispatch(alert());
			  }
		}
	}
}

export const forgotPasswordSubmitAction = ({email , password , code}: any) => {
	return async (dispatch: Dispatch) => {
		dispatch(toggleComponentLoading(ActionType.CHANGE_PASSWORD_LOADING , true));
		dispatch(checkIsPasswordValid(code, ActionType.RESET_PASSWORD_CODE_CHANGE, "", false));
		
		try {
			// await Auth.forgotPasswordSubmit(email, code, password);
			await confirmResetPassword({ 
				username: email,
				newPassword: password,
				confirmationCode: code
			});
			dispatch(toggleComponentLoading(ActionType.CHANGE_PASSWORD_LOADING , false))
			dispatch(updatePassowrdResetState('START'));
			dispatch({
				type: ActionType.PASSWORD_RESET_COMPLETED
			})
			dispatch(push('/'));
		} catch (error: any) {
			dispatch(toggleComponentLoading(ActionType.CHANGE_PASSWORD_LOADING , false))
			console.log(error);
			switch (error.name) {
				case "CodeMismatchException":
					dispatch(alert(error.message));
					dispatch(checkIsPasswordValid(code, ActionType.RESET_PASSWORD_CODE_CHANGE, "The provided code is incorrect", false));
				  	return;
				case "LimitExceededException":
					dispatch(alert(error.message));
					return;
				case "InvalidParameterException":
					dispatch(alert(error.message));
					return;
				default:
					dispatch(alert());
			  }

		}
	}
}