import { ActionType } from "state/action-types";
import { Actions, reducerState } from "types/chatv2";
import { Message } from "types/message";
import { removeNoCacheQuestion } from "utils/subscribe";

export const initialState: reducerState = {
  debug_mode: true,
  questionHistoryVisiblilty: false,
  searchChatQuery:"",
  chats: [],
  page: 0,
  historyItems: [],
  subscription: {
    eventReceiver: null,
  },
  scrollMessagesListToBottom: false,
  isAnswerModalOpen: false,
  limit: 30,
  huma_citation_link:"",
  chatInputFocusTrigger: 0,
  has_next_page: true,
  messages: [],
  has_more_messages: false,
  value: "",
  chatQueryString : "",
  current_thread_details: null,
  sourceRecords: {
    type: '',
    publication: '',
    huma_link: '',
    source_question: '',
    source: '',
    publication_date: '',
    visibility: '',
    keyword: [],
    link: '',
    pk: 0,
  },
  citationHref: "",
  currentCitationHref: "",
  isSourceRecordsLoading: false,
  sourceRecordsPosition: {
    isRight: false,
    isBottom: false,
    top: 0,
    left: 0,
  },
  projects: [],
  has_more_projects: false,
  isProjectFilesDeleting: false,
  isHistoryItemLoading: false,
  project_page: 0,
};

const reducer = (state = initialState, action: Actions): reducerState => {
  switch (action.type) {
    case ActionType.UPDATE_CHATS_THREADS:
      return {
        ...state,
        chats: action.payload,
      };
    case ActionType.UPDATE_CHAT_DEBUG_MODE:
      return {
        ...state,
        debug_mode: action.payload,
      };
    case ActionType.UPDATE_QUESTION_HISTORY_VISIBILITY:
      return {
        ...state,
        questionHistoryVisiblilty: action.payload,
      };
    case ActionType.UPDATE_SEARCH_CHAT_QUERY:
      return {
        ...state,
        searchChatQuery: action.payload,
      };
    case ActionType.UPDATE_HAS_MORE_CHAT_THREADS:
      return {
        ...state,
        has_next_page: action.payload,
      };
    case ActionType.SET_CHAT_THREAD_MESSAGES:
      return {
        ...state,
        // remove duplicates and keeps latest version of message using map and reverse
        messages: Array.from(new Map([...action.payload].reverse().map(message => [message.id, message])).values()).reverse(),
      };
    case ActionType.SET_CURRENT_CHAT_THREAD:
      return {
        ...state,
        current_thread_details: action.payload,
      };
    case ActionType.UPDATE_HAS_MORE_MESSAGES:
      return {
        ...state,
        has_more_messages: action.payload,
      };
    case ActionType.SET_VALUE:
      return {
        ...state,
        value: action.payload,
      };
      case ActionType.SET_SUBSCRIPTION:
        const newSubs = {
          ...state.subscription,
          [action.payload.name]: action.payload.value,
        };
        return { ...state, subscription: newSubs };
        
    case ActionType.SCROLL_MESSAGES_LIST_TO_BOTTOM:
      return { 
        ...state,
        scrollMessagesListToBottom: action.payload
      };
    case ActionType.FOCUS_CHAT_INPUT:
      return {
        ...state,
        chatInputFocusTrigger: Math.random(),
      };
    case ActionType.SET_CHAT_INPUT_VALUE:
      return { 
        ...state,
        chatQueryString: action.payload.q
      };
    case ActionType.UPDATE_HISTORY_ITEM:
      const itemIds = action.payload.items.map((h: any) => h.utteranceId);
      const newHistoryItems = [
        ...state.historyItems.filter(
          (h: any) => !itemIds.includes(h.utteranceId)
        ),
        ...action.payload.items,
      ];
      return {
        ...state,
        historyItems: newHistoryItems,
        scrollMessagesListToBottom: true,
      };
    
    case ActionType.TOGGLE_ANSWER_MODAL:
      return {
        ...state,
        isAnswerModalOpen: action.payload,
      };

    case ActionType.TOGGLE_MESSAGE_LOGS_VISIBILITY:
      return {
        ...state,
        messages: state.messages.map((message:Message) => {
          if(message.id===action.payload){
            return {
              ...message,
              showLogs: !message.showLogs,
            }
          } else{
            return message;
          }
        }),
      };
    case ActionType.TOGGLE_MESSAGE_COMPARE:
      return {
        ...state,
        messages: state.messages.map((message: Message) => {
          if (message.id === action.payload) {
            return {
          ...message,
          is_compare: !message.is_compare,
        };
      }
      return message;
    }),
  };
    
    // visual history actions
    case ActionType.UPDATE_CHAT_CHART_OPTION_USER_HISTORY:
      return {
        ...state,
        historyItems: state.historyItems.map((utterance) => {
          if (
            removeNoCacheQuestion(utterance.utteranceId) ===
            action.payload.utteranceId
          ) {
            utterance.visual.chart_options = action.payload.chart_options;
          }
          return utterance;
        }),
      };

    case ActionType.UPDATE_CHAT_HISTORY_PDF_STATUS_BY_UTTERANCE_ID:
      const { pdf_status, utterance_id } = action.payload;
      return {
        ...state,
        historyItems: state.historyItems.map((utterance) => {
          if (utterance.utteranceId === utterance_id) {
            utterance.pdf_status = pdf_status;
          }
          return utterance;
        }),
      };

    case ActionType.UPDATE_USER_CHAT_HISTORY_VISUAL_BY_QUESTION:
      return {
        ...state,
        historyItems: state.historyItems.map((utterance) => {
          if (
            removeNoCacheQuestion(utterance.utterance) ===
            action.payload.utterance
          ) {
            utterance.subscribed = action.payload.subscribed;
            utterance.subscribed_id = action.payload.subscribed_id;
          }
          return utterance;
        }),
      };
    
    case ActionType.UPDATE_CHAT_HISTORY_VISUAL:
      return {
        ...state,
        historyItems: state.historyItems.map((element) => {
          if (element.utteranceId === action.payload.id) {
            element.is_starred = action.payload.value;
          }
          return element;
        }),
      };
    case ActionType.SET_CITATION_HREF:
      return {
        ...state,
        citationHref: action.payload,
      };
    case ActionType.SET_SOURCE_RECORDS:
      return {
        ...state,
        sourceRecords: action.payload,
      };
    case ActionType.SET_IS_SOURCE_RECORDS_LOADING:
      return {
        ...state,
        isSourceRecordsLoading: action.payload,
      };
    case ActionType.SET_SOURCE_RECORDS_POSITION:
      return {
        ...state,
        sourceRecordsPosition: action.payload,
      };
    case ActionType.SET_CURRENT_CITATION_HREF:
      return {
        ...state,
        currentCitationHref: action.payload,
      };
    case ActionType.UPDATE_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };
    case ActionType.UPDATE_PROJECTS_HAS_MORE:
      return {
        ...state,
        has_more_projects: action.payload,
      };
    case ActionType.SET_IS_PROJECT_FILES_DELETING:
      return {
        ...state,
        isProjectFilesDeleting: action.payload,
      };
    case ActionType.UPDATE_HISTORY_ITEM_LOADING:
      return {
        ...state,
        isHistoryItemLoading: action.payload,
      };
    case ActionType.UPDATE_CHAT_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case ActionType.UPDATE_PROJECT_PAGE:
      return {
        ...state,
        project_page: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;